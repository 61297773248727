const settings = {
    BASE_URL: "https://amibo.logisticiq.cz",
    BASE_API_URL : undefined,
    GLOBAL_PREFERENCES_URL : undefined,
    DOCS_TOPICS_URL: "https://docs.logisticiq.cz/topic/",
    VERSION: 'master/243de1fb',
};
settings['BASE_API_URL'] = settings.BASE_URL + "/api/"
settings['GLOBAL_PREFERENCES_URL'] = settings.BASE_API_URL + "service/preferences/"
settings['GLOBAL_CONFIGURATION_URL'] = settings.BASE_API_URL + "service/configuration/"

export default settings;
